<template>
  <b-card class="hp-card-6">
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-between mb-32">
          <div class="d-flex align-items-center">
            <h5 class="mb-0 mr-8">Subscribe Goal</h5>
            <p class="mb-0 hp-badge-text">Social Media</p>
          </div>

          <i
            class="ri-more-fill hp-text-color-dark-0"
            style="font-size: 24px"
          ></i>
        </div>

        <b-row>
          <b-col cols="12">
            <b-row align-v="center" class="mr-16">
              <b-col class="hp-flex-none w-auto">
                <div class="hp-social-media-logo-wrapper bg-primary-2">
                  <i
                    class="ri-linkedin-fill text-black-0 hp-social-media-logo"
                  ></i>
                </div>
              </b-col>

              <b-col>
                <h5 class="mb-0 hp-placeholder">Linkedin</h5>

                <div class="d-flex align-items-center">
                  <b-progress
                    class="flex-grow-1"
                    variant="primary-2"
                    height="4px"
                    value="30"
                  ></b-progress>

                  <span class="text-black-80 ml-6">30%</span>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12 mt-24">
            <b-row align-v="center" class="mr-16">
              <b-col class="hp-flex-none w-auto">
                <div class="hp-social-media-logo-wrapper bg-primary">
                  <i
                    class="ri-facebook-fill text-black-0 hp-social-media-logo"
                  ></i>
                </div>
              </b-col>

              <b-col>
                <h5 class="mb-0 hp-placeholder">Facebook</h5>

                <div class="d-flex align-items-center">
                  <b-progress
                    class="flex-grow-1"
                    variant="primary"
                    height="4px"
                    value="30"
                  ></b-progress>

                  <span class="text-black-80 ml-6">30%</span>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12 mt-24">
            <b-row align-v="center" class="mr-16">
              <b-col class="hp-flex-none w-auto">
                <div class="hp-social-media-logo-wrapper bg-danger-1">
                  <i
                    class="ri-instagram-fill text-black-0 hp-social-media-logo"
                  ></i>
                </div>
              </b-col>

              <b-col>
                <h5 class="mb-0 hp-placeholder">Instagram</h5>

                <div class="d-flex align-items-center">
                  <b-progress
                    class="flex-grow-1"
                    variant="danger"
                    height="4px"
                    value="30"
                  ></b-progress>

                  <span class="text-black-80 ml-6">30%</span>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <div class="col-12 mt-24">
            <b-row align-v="center" class="mr-16">
              <b-col class="hp-flex-none w-auto">
                <div class="hp-social-media-logo-wrapper bg-info-1">
                  <i
                    class="ri-twitter-fill text-black-0 hp-social-media-logo"
                  ></i>
                </div>
              </b-col>

              <b-col>
                <h5 class="mb-0 hp-placeholder">Twitter</h5>

                <div class="d-flex align-items-center">
                  <div class="progress flex-grow-1" style="height: 4px">
                    <b-progress
                      class="flex-grow-1"
                      variant="info-1"
                      height="4px"
                      value="30"
                    ></b-progress>
                  </div>

                  <span class="text-black-80 ml-6">30%</span>
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="col-12 mt-24">
            <b-row align-v="center" class="mr-16">
              <b-col class="hp-flex-none w-auto">
                <div class="hp-social-media-logo-wrapper bg-black-80">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    role="img"
                    viewBox="0 0 24 24"
                    class="text-black-0 hp-social-media-logo"
                    height="16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"
                    ></path>
                  </svg>
                </div>
              </b-col>

              <b-col>
                <h5 class="mb-0 hp-placeholder">Tiktok</h5>

                <div class="d-flex align-items-center">
                  <b-progress
                    class="flex-grow-1"
                    variant="black-80"
                    height="4px"
                    value="30"
                  ></b-progress>
                  <span class="text-black-80 ml-6">30%</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BProgress } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BProgress,
  },
};
</script>
