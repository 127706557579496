var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"border-0 hp-card-2 px-12 py-16 hp-upgradePlanCardOne"},[(this.$store.state.themeConfig.theme == 'dark')?_c('div',{staticClass:"position-absolute w-100 h-100",style:({
      top: 0,
      left: 0,
      backgroundImage:
        'url(' +
        require('@/assets/img/dashboard/analytics-download-bg-dark.png') +
        ')',
      backgroundSize: 'cover',
      backgroundPosition: 'right center',
      zIndex: -1,
    })}):_c('div',{staticClass:"position-absolute w-100 h-100",style:({
      top: 0,
      left: 0,
      backgroundImage:
        'url(' +
        require('@/assets/img/dashboard/analytics-download-bg.png') +
        ')',
      backgroundSize: 'cover',
      backgroundPosition: 'right center',
      zIndex: -1,
    })}),_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('h5',{staticClass:"mb-0 text-black-0"},[_vm._v(" Check the Best Prices of New Models & Boost Your Business ")]),_c('b-button',{staticClass:"mt-32 border-primary bg-black-0 hp-bg-color-dark-primary-1 text-primary hp-text-color-dark-0",attrs:{"variant":"none"}},[_c('i',{staticClass:"iconly-Light-Download remix-icon"}),_c('span',[_vm._v("Download")])])],1),_c('b-col',{staticClass:"hp-flex-none w-auto position-absolute h-100 pr-0",staticStyle:{"top":"0","right":"0"}},[_c('img',{staticClass:"h-100 hp-dir-scale-x-n1",attrs:{"src":require('@/assets/img/dashboard/analytics-download-vector.svg'),"alt":"Check the Best Prices of New Models & Boost Your Business"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }